import React, { useState, useEffect } from "react";
import {
  Brain,
  Lock,
  Search,
  Calendar,
  CheckSquare,
  Zap,
  Cpu,
  Globe,
  Smartphone,
  Laptop,
  Tablet,
  Menu,
  X as Close
} from "lucide-react";

const LandingPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setIsVisible(true);

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 font-sans">
      <div className="absolute inset-0 bg-gradient-to-br from-purple-900 to-blue-900 opacity-50 z-0"></div>
      <div className="relative z-10">
        <header className={`fixed w-full transition-transform duration-300 ${visible ? 'translate-y-0' : '-translate-y-full'} bg-gray-900 bg-opacity-90 shadow-lg z-50`}>
          <div className="container mx-auto px-4 py-4">
            <nav className="flex items-center justify-between">
              <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                Cynaptic
              </div>
              {/* Desktop Menu */}
              <div className="hidden md:flex space-x-6">
                <a
                  href="#features"
                  className="hover:text-pink-400 transition duration-300"
                >
                  Features
                </a>
                <a
                  href="#privacy"
                  className="hover:text-pink-400 transition duration-300"
                >
                  Privacy
                </a>
                <button className="bg-gradient-to-r from-purple-500 to-pink-500 px-6 py-2 rounded-full font-semibold hover:from-purple-600 hover:to-pink-600 transition duration-300 shadow-lg">
                  Get Cynaptic
                </button>
              </div>
              {/* Mobile Menu Button */}
              <button
                className="md:hidden text-3xl text-white"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {isMenuOpen ? <Close /> : <Menu />}
              </button>
            </nav>
          </div>
        </header>

        {/* Mobile Menu */}
        <div
          className={`md:hidden fixed top-0 left-0 w-full h-screen bg-gray-900 bg-opacity-95 text-gray-100 shadow-lg transition-transform transform z-50 ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <nav className="flex flex-col items-center mt-12 relative">
            <button
              className="absolute top-4 right-4 text-3xl text-white"
              onClick={() => setIsMenuOpen(false)}
            >
              <Close />
            </button>
            <a
              href="#features"
              className="text-2xl mb-4 hover:text-pink-400 transition duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              Features
            </a>
            <a
              href="#privacy"
              className="text-2xl mb-4 hover:text-pink-400 transition duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              Privacy
            </a>
            <button
              className="bg-gradient-to-r from-purple-500 to-pink-500 px-6 py-2 rounded-full font-semibold hover:from-purple-600 hover:to-pink-600 transition duration-300 shadow-lg"
              onClick={() => setIsMenuOpen(false)}
            >
              Get Cynaptic
            </button>
          </nav>
        </div>

        <main className="container mx-auto px-4 py-16 pt-32">
          <section
            className={`text-center mb-24 transition-opacity duration-1000 ${
              isVisible ? "opacity-100" : "opacity-0"
            }`}
          >
            <h1 className="text-6xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
              Your Digital Second Brain, Supercharged
            </h1>
            <p className="text-2xl mb-8 max-w-3xl mx-auto">
              Thought AI assistants were impressive? Cynaptic takes it to a
              whole new level; a supercharged extension of yourself. Experience
              the future of personal digital organization and transcend human
              limitations with AI that thinks ahead.
            </p>
            <button className="bg-gradient-to-r from-purple-500 to-pink-500 px-8 py-4 rounded-full font-bold text-lg hover:from-purple-600 hover:to-pink-600 transition duration-300 shadow-lg transform hover:scale-105">
              Revolutionize Your Digital World
            </button>
          </section>

          <section id="features" className="mb-24">
            <h2 className="text-4xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
              Features That Redefine Smart
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
              <FeatureCard
                icon={<Search className="w-12 h-12 text-yellow-400" />}
                title="Intelligent Indexing"
                description="Effortlessly search your entire digital universe across all devices. Instantly access information from files, emails, and documents—whether image, PDF, or doc. It doesn't just recall; it understands, correlates, and predicts, keeping you one step ahead."
              />
              <FeatureCard
                icon={<Cpu className="w-12 h-12 text-blue-400" />}
                title="Seamless Integration"
                description="Syncs flawlessly with all your favorite apps, including Calendar, Notion, Todo, and more. Your apps don't just connect; they collaborate to streamline your workflow, turning complexity into simplicity."
              />
              <FeatureCard
                icon={<Brain className="w-12 h-12 text-pink-400" />}
                title="Proactive Assistant"
                description="Your AI-powered ally that anticipates tasks and events based on your interactions. It's more than just managing; it's predicting and simplifying, ensuring your needs are met before you even recognize them."
              />
              <FeatureCard
                icon={<Lock className="w-12 h-12 text-green-400" />}
                title="Unbreakable Security"
                description="Featuring state-of-the-art Fully Homomorphic Encryption, your data is secure and private. Our encryption technology is so advanced, it makes quantum computers look outdated. Your data is not just safe; it's virtually invincible."
              />
              <FeatureCard
                icon={<Calendar className="w-12 h-12 text-purple-400" />}
                title="Smart Scheduling"
                description="Transform your calendar into a seamless extension of your daily life. Cynaptic doesn't just organize your events; it automagically predicts and schedules them based on your digital interactions. Say goodbye to manual entry and conflicting events."
              />
            </div>
          </section>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <section id="privacy" className="text-center mb-8">
              <div className="flex justify-center items-center mb-6">
                <Lock className="w-16 h-16 mr-4 text-green-400" />
                <h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                  Privacy that Sets the Standard
                </h2>
              </div>
              <p className="text-lg max-w-2xl mx-auto">
                We're so ahead of the curve, quantum computers are still playing
                catch-up. Your data isn't just safe, it's in its own digital
                fortress.
              </p>
            </section>
            <section className="text-center mb-8">
              <div className="flex justify-center items-center mb-6">
                <Zap className="w-16 h-16 mr-4 text-yellow-400" />
                <h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                  Ready to Upgrade Your Brain?
                </h2>
              </div>
              <p className="text-xl mb-8 max-w-3xl mx-auto">
                Don't just join the future. Be the future. Cynaptic isn't
                just next-level, it's the level after that.
              </p>
            </section>
            <section className="text-center mb-8">
              <h2 className="text-4xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                Your Digital Life 2.0
              </h2>
              <p className="text-xl max-w-3xl mx-auto mb-8">
                Imagine if your brain had a turbo button. That's Cynaptic.
                It's not just managing your digital life, it's supercharging it.
              </p>
              <div className="flex justify-center space-x-8">
                <IconBox
                  icon={<Search className="w-8 h-8" />}
                  label="Find Anything"
                />
                <IconBox
                  icon={<Calendar className="w-8 h-8" />}
                  label="Predict Everything"
                />
                <IconBox
                  icon={<CheckSquare className="w-8 h-8" />}
                  label="Do Everything"
                />
                <IconBox
                  icon={<Globe className="w-8 h-8" />}
                  label="Rule Everything"
                />
              </div>
            </section>


            <section className="text-center mb-8">
              <h2 className="text-4xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                Available Everywhere You Are
              </h2>
              <div className="flex justify-center space-x-12 mb-8">
                <PlatformIcon
                  icon={<Smartphone className="w-12 h-12" />}
                  label="iOS & Android"
                />
                <PlatformIcon
                  icon={<Laptop className="w-12 h-12" />}
                  label="Windows"
                />
                <PlatformIcon
                  icon={<Tablet className="w-12 h-12" />}
                  label="Tablet"
                />
              </div>
              <p className="text-xl max-w-3xl mx-auto">
                Cynaptic ensures your digital life is organized and
                accessible across all your devices.
              </p>
            </section>

          </div>
          <div className="flex justify-center mb-24">
          <button className="bg-gradient-to-r from-purple-500 to-pink-500 px-8 py-4 rounded-full font-bold text-lg hover:from-purple-600 hover:to-pink-600 transition duration-300 shadow-lg transform hover:scale-105">
            Sign Up for the waitlist
          </button>
          </div>
          
        </main>

        <footer className="bg-gray-800 py-8">
          <div className="container mx-auto px-8 text-center">
            <p>&copy; 2024 Cynaptic. All rights reserved.</p>
            <p className="mt-2">Redefining 'smart' since before it was cool.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-gray-800 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
    <div className="flex items-center mb-4">
      {icon}
      <h3 className="text-2xl font-bold ml-4">{title}</h3>
    </div>
    <p className="text-lg">{description}</p>
  </div>
);

const IconBox = ({ icon, label }) => (
  <div className="flex flex-col items-center text-gray-100">
    {icon}
    <p className="mt-2 text-lg">{label}</p>
  </div>
);

const PlatformIcon = ({ icon, label }) => {
  return (
    <div className="flex flex-col items-center group">
      <div className="bg-gray-800 bg-opacity-50 p-4 rounded-full mb-2 group-hover:bg-purple-600 transition duration-300">
        {icon}
      </div>
      <span className="group-hover:text-pink-400 transition duration-300">
        {label}
      </span>
    </div>
  );
};

export default LandingPage;